import React from 'react';

function Footer() {
  return (
    <div className="Footer">
        <p>Kyle Fasanella 2022</p>
    </div>
  );
}

export default Footer;
